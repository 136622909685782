<template>
  <div style="position: absolute; bottom: 0" :style="$vuetify.breakpoint.smAndDown && 'left: 5%; font-size: 14px; position: relative!important; padding-bottom: 4px'">
    <span v-if="isMobile && $vuetify.breakpoint.smAndDown" class="d-flex white--text ma-2 pb text-center">
      &copy; {{new Date().getFullYear()}} | <a :href="$store.state.baseUrl + $store.state.linkPrivacyPolicy" target="_blank" class="white--text ml-2">{{labelPrivacyPolicy}}</a>
    </span>
    <span v-if="!isMobile" class="white--text ma-2" style="text-shadow: 0 0 8px black; font-size: 12px">
      &copy; {{new Date().getFullYear()}} {{labelCopyRight}} | <a :href="$store.state.baseUrl + $store.state.linkPrivacyPolicy" target="_blank" class="white--text" style="text-shadow: 0 0 8px black;">{{labelPrivacyPolicy}}</a>
    </span>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  props: ['isMobile'],
  data: () => ({
    labelCopyRight: 'todos os direitos reservados',
    labelPrivacyPolicy: 'política de privacidade'
  })

}
</script>

