<template>
  <div  class="image-slide d-flex flex-fill justify-end">
    <div class="image-slide-btn-close d-inline-block" @click="$router.back()">
      <v-icon color="#999">mdi-close</v-icon>
    </div>
    <v-overlay opacity="1">
      <div :style="$vuetify.breakpoint.smAndUp ? 'max-width: 97vh' : 'max-width: 100vw'">
        <v-carousel
            height="auto"
            hide-delimiter-background
            show-arrows-on-hover
            hide-delimiters
            @change="onChange"
            v-model="slide"
        >
          <v-carousel-item v-for="(img, i) in images" cover :key="i" :eager="true" :src="`${$store.state.baseUrlResources}resources/images/cod_${data.cod}/${i+1}.jpg`" />
        </v-carousel>
        <div class="my-6 py-5"><!-- Row Space --></div>
        <div class="text-center grey--text" style="position: fixed; bottom: 10px; left: 0; width: 100%">{{slide+1}} / {{images.length}}</div>
        <div v-if="loading" class="cssanimation fadeIn d-flex justify-center align-center" style="height: 100%; position: absolute; left: -13px">
          <v-progress-circular indeterminate />
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "ImageSlide",
  props: ['data','slide'],
  data: () => ({
    loading: true,
  }),
  methods: {
    onChange() {
      this.loading = false
      const time = setTimeout(() => {
        this.loading = true
        clearTimeout(time)
      }, 300)
    }
  },
  computed: {
    images() {
      return Array(this.data.imgs).fill(null)
    }
  }
}
</script>

