<template>
  <div class="sidebar-app pa-2 fill-height">
    <div class="d-flex align-center flex-column">
      <div :style="$vuetify.breakpoint.mdAndUp ? 'width: 140px; height: 110px' : 'width: 100px; height: 80px'">
        <v-img :src="data.logo"/>
      </div>
      <div class="white--text text-center mb-2">
        <h6 class="pb-3">{{data.captionLogo}}</h6>
        <h6 class="font-weight-medium"><i>{{data.subtitle}}</i></h6>
      </div>
    </div>
    <v-divider dark class="my-2" />
    <v-list color="transparent" dark style="margin: 0 -8px; padding: 0;">
      <v-list-item v-for="(item, i) in data.menu" :key="i" :to="item.link" :ripple="false">
        <h4 v-if="!item['submenu']" class="text-uppercase">{{item.label}}</h4>
        <v-list-group v-if="item['submenu']" no-action :ripple="false">
          <template #activator>
            <h4 class="text-uppercase">{{item.label}}</h4>
          </template>
          <v-list-item v-for="(subitem, j) in item['submenu'] || []" :key="j" :ripple="false" :to="subitem.link">
            <h5 class="text-uppercase">{{subitem.label}}</h5>
          </v-list-item>
        </v-list-group>
      </v-list-item>
    </v-list>
    <v-divider dark class="my-2" />
    <v-list color="transparent" dark class="sidebar-app-footer">
      <v-list-item class="d-flex justify-center px-0 mb-2 d-sm-flex d-md-none">
        <v-btn small color="green" :href="$store.state.ulrWhatsApp">
          {{labelWhatsApp}}
          <v-icon class="ml-3">mdi-whatsapp</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item class="py-1 pl-1 d-flex align-start" v-for="(item,i) in data.menuFooter" :key="i">
        <v-icon class="pr-2" v-text="item.icon" />
        <h5 class="font-weight-medium">{{item.label}}</h5>
      </v-list-item>
      <v-list-item class="d-flex justify-center px-0 mt-2 d-sm-flex d-md-none">
        <SocialMedia :data="store.sidebarRightApp" />
      </v-list-item>
    </v-list>
    <PrivacyPolicy :is-mobile="true" />
  </div>
</template>

<script>
import SocialMedia from "@/components/contents/SocialMedia";
import PrivacyPolicy from "@/components/contents/PrivacyPolicy";
export default {
  name: "SidebarApp",
  components: {PrivacyPolicy, SocialMedia},
  props: ['data','store'],
  data: () => ({
    labelWhatsApp: 'Abrir Chat WhatsApp'
  })
}
</script>
