<template>
  <div class="document-panel mb-2" style="overflow: hidden; overflow-y: auto; height: calc(100vh - 158px)">
    <v-card class="pa-md-4">
      <!-- Title -->
      <v-card-title class="text-uppercase text-break">
        {{data.title}}
      </v-card-title>
      <!-- Subtitle -->
      <v-card-subtitle style="max-width: 600px">
        {{data.subtitle}}
      </v-card-subtitle>
      <!-- Panel -->
      <v-card-text>
       <div class="d-flex flex-column mt-5">
         <!-- Files -->
         <v-row v-for="(item, i) in data.files" :key="i">
           <v-col sm="6">
             <!-- Desc -->
             <h4 class="subtitle-1 text-uppercase d-flex align-start">
               <v-icon class="mr-2" color="primary">mdi-file-document-outline</v-icon>
               {{item.desc}}
             </h4>
           </v-col>
           <!-- Button -->
           <v-col cols="12" sm="2" class="text-center text-sm-right">
             <v-btn color="primary" small tag="a" :href="`${$store.state.baseUrl}${item.link}`" target="_blank">
               <v-icon class="mr-2">mdi-download</v-icon>
               {{labelBtnDownload}}
             </v-btn>
           </v-col>
           <!-- Divider -->
           <v-col sm="8">
             <v-divider />
           </v-col>
         </v-row>
       </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DocumentPanel",
  data: () => ({labelBtnDownload: 'baixar'}),
  props: ['data']
}
</script>
