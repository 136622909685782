<template>
  <div class="sidebar-right-app fill-height">
    <div class="d-flex justify-center pt-6">
      <SocialMedia :data="data" />
    </div>
    <div class="sidebar-right-app-footer d-flex flex-column align-center justify-end pb-6">
      <div class="cssanimation fadeInTop">
        <v-btn link icon :href="$store.state.ulrWhatsApp">
          <v-img width="55" :src="data.whatsappImg" />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import SocialMedia from "@/components/contents/SocialMedia";
export default {
  name: "SidebarRightApp",
  components: {SocialMedia},
  props: ['data']
}
</script>
