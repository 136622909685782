<template>
  <div class="home fill-height transparent flex-fill px-3">
    <HeaderPanel :data="store.homeHeader" v-if="$vuetify.breakpoint.mdAndUp" />
    <HomePanel v-if="$vuetify.breakpoint.mdAndUp" />
    <HomePanelVertical v-if="$vuetify.breakpoint.smAndDown" />
  </div>
</template>

<script>
import HomePanel from "@/components/contents/HomePanel";
import HeaderPanel from "@/components/headers/HeaderPanel";
import axios from "axios";
import HomePanelVertical from "@/components/contents/HomePanelVertical2";

export default {
  name: "Home",
  components: {HomePanelVertical, HeaderPanel, HomePanel},
  computed: {
    store() {
      return  this.$store.state.home
    }
  },
  beforeCreate() {
    axios.head(this.$store.state.baseUrl + 'home').then(() => {
      if(sessionStorage.getItem('redirect') === 'true') {
        const to = sessionStorage.getItem('redirectTo')
        if(to !== '/') {
          this.$router.push(to)
          sessionStorage.removeItem('redirectTo')
          sessionStorage.removeItem('redirect')
        }
      }
    })
  }
}
</script>

