
function about() {
    return {
        state: {
            homeHeader: {title: 'sobre'},
            descriptions: [
                {
                    desc:'Nós da Furini Gestor imobiliário atuamos desde 2012 na Cidade de Franca SP.',
                    img: require('@/assets/img/img_about_1.jpg')
                },
                {
                    desc: 'Nossa principal atividade é na intermediação em negócios imobiliários e acessória jurídica imobiliária,\n' +
                        ' basicamente através da locação e venda de imóveis e também prestando acessória nas causas do direito imobiliário.',
                    img: require('@/assets/img/img_about_2.jpg')
                },
                {
                    desc: 'Nossa missão é prestar serviço de valor no ramo imobiliário seguindo sempre os princípios da legalidade, eficiência,\n' +
                        ' moralidade, publicidade e transparência, contribuindo desta forma, na realização e desenvolvimento da comunidade.',
                    img: require('@/assets/img/img_about_3.jpg')
                }
            ]
        }
    }
}

export default about()
