<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
if(location.hostname !== 'localhost' && location.protocol === 'http:') {
  let url = location.href
  url = url.replace(/http:/g,'https:')
  console.log('redirect to', url)
  location.href = url
}
</script>
