<template>
  <div class="documents fill-height transparent flex-fill px-3">
    <HeaderPanel :data="store.homeHeader" />
    <BackButton label="Home" to="/" v-if="$vuetify.breakpoint.smAndDown" />
    <DocumentPanel :data="store" />
  </div>
</template>

<script>
import HeaderPanel from "@/components/headers/HeaderPanel";
import DocumentPanel from "@/components/contents/DocumentPanel";
import BackButton from "@/components/buttons/BackButton";
export default {
  components: {BackButton, HeaderPanel, DocumentPanel},
  name: "Documents",
  computed: {
    store() {
      return  this.$store.state.documents
    }
  }
}
</script>

