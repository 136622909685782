<template>
  <div class="not-found fill-height transparent flex-fill px-3">
    <div class="not-found-panel mb-2 pt-4 fill-height">
      <v-card class="pa-4" height="91vh">
        <v-row>
          <v-col cols="12" sm="5" class="d-flex justify-md-end">
            <div>
              <v-img
                contain
                width="36vh"
                height="28vh"
                :src="require('@/assets/svg/not-found.svg')"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="7" class="d-flex justify-start">
            <v-layout column justify-start>
              <div class="display-1">Página não encontrada</div>
              <div v-if="url" class="mt-3 caption">
                O imóvel não está mais disponível.
              </div>
              <div v-else class="mt-3 caption">
                Página incorreta, ou não está disponível.
              </div>
              <small v-if="url" style="font-size: 12px" class="mt-4 light-blue--text text--darken-4">URL: {{url}}</small>
              <div class="mt-8">
                <v-btn text x-small color="primary" :to="{name: 'Home'}">
                  <v-icon x-small>mdi-arrow-left</v-icon>
                  ir para home
                </v-btn>
              </div>
            </v-layout>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  props: ['url']
};
</script>

