<template>
  <div class="for-rent fill-height transparent flex-fill d-flex align-center flex-column">
    <HouseList :data="store.data" :store="store" :header-panel="store.headerPanelForSale" :empty="store.status !== 1 ? store.data.length === 0 : false" />
  </div>
</template>

<script>
import HouseList from "@/components/contents/HouseList";
import {actions} from "@/store/actions/properties";
export default {
  name: "ForSale",
  components: {HouseList},
  computed: {
    store() {
      return this.$store.state.properties
    }
  },
  mounted() {
    this.$store.dispatch(actions.GET_PROPERTIES, {business: 2})
  }
}
</script>

