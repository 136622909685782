<template>
  <div class="layout-vertical fill-height">
    <!-- Row Top -->
    <div class="row-top">
      <HeaderApp :data="data.headerApp" />
    </div>
    <!-- Row Bottom -->
    <div class="row-bottom d-flex">
      <router-view />
    </div>
    <!-- Sidebar -->
    <v-navigation-drawer color="primary" v-model="data.headerApp.drawer" absolute temporary>
      <SidebarApp :data="data.sidebarApp" :store="data"/>
    </v-navigation-drawer>
  </div>
</template>

<script>
import SidebarApp from "@/components/sidebars/SidebarApp";
import HeaderApp from "@/components/headers/HeaderApp";
export default {
  name: "LayoutVertical",
  components: {HeaderApp, SidebarApp},
  props: ['data']
}
</script>

