<template>
  <v-card class="home-header d-flex my-3">
    <v-card-text class="pa-md-3">
      <h2 class="headline sm1 text-uppercase" style="line-height: 1.2">
        {{data.title}}<span v-if="subtitle" class="pl-2">- {{data.subtitle}}</span>
      </h2>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "HeaderPanel",
  props: ['data','subtitle']
}
</script>

