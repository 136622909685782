export default {
    methods: {
        system() {
            return {
                capitalize(string = '') {
                    return string.split(/\s/g).map(str => {
                        return str.charAt(0).toUpperCase() + str.slice(1);
                    }).join(' ')
                },
                getBaseHost() {
                    return window.location.origin
                }
            }
        }
    }
}
