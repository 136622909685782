import Vue from 'vue'

Vue.filter('currency', function (value) {
    if (!value) return '(inválido)'
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
        .replaceAll(/R\$\s/g,'R$ ')
})

Vue.filter('capitalize', function (value) {
    return !value ? value : value.charAt(0).toUpperCase() + value.slice(1);
})

Vue.filter('translateBusiness', function (value) {
    if (!value) return '(inválido)'
    return value === 'aluga-se' ? 'aluguel' : value === 'vende-se' ? 'venda' : value
})

Vue.filter('translateBusinessToAct', function (value) {
    if (!value) return '(inválido)'
    return value === 'aluga-se' ? 'alugar' : value === 'vende-se' ? 'comprar' : value
})

Vue.filter('decimalToString', function (value) {
    if (value === 0) return '0'
    if (!value) return '(inválido)'
    return typeof value === 'number' ? value.toString().replace('.',',') : value
})
