<template>
  <div class="form-panel" style="position: absolute; z-index: 9;" v-show="getters['contactForm/hasBusiness'](type)">
    <!-- Overlay -->
    <v-overlay opacity="0.45">
      <!-- Card -->
      <v-card color="white" light style="min-width: 350px; max-width: 350px; overflow: hidden auto; height: calc(100vh - 20px); margin: 30px 0;" :style="$vuetify.breakpoint.smAndDown || 'height: calc(100vh - 34px)'">
        <div id="form" v-show="mail.status === 0 && data.statusAdvert <= 0">
          <!-- Tiitle -->
          <v-card-title class="text-capitalize">{{property && property['price'] === -1 ? data.title : getters['contactForm/getTitle'](type)}}</v-card-title>
          <!-- Subtitle -->
          <v-card-subtitle>{{data.subtitle}}</v-card-subtitle>
          <!-- Form -->
          <v-form class="pa-3 pt-0" v-model="formValid">
            <!-- Name -->
            <v-text-field :label="labelName" v-model="form.name" maxlength="60" :rules="[rules.required, rules.name]" />
            <!-- Phone -->
            <v-text-field :label="labelPhone" inputmode="numeric" v-model="form.phone" maxlength="15" :rules="[rules.required, rules.phone]" v-mask="['(##) #####-####', '(##) ####-####']"/>
            <!-- Email -->
            <v-text-field :label="labelEmail" v-model="form.email" :rules="[rules.email]" maxlength="50"/>
            <!-- Message -->
            <v-textarea v-model="form.message" :label="labelMessage" maxlength="2000" counter="2000"/>
            <!-- Label required -->
            <v-card-text class="subtitle-1 m-0 py-0">
              <small><i>{{labelRequired}}</i></small>
              <div class="d-flex align-center">
                <v-checkbox v-model="agreePrivacyPolicy">
                  <template #label>
                    <small>{{labelAgree}}</small>
                  </template>
                </v-checkbox>
                <small>
                  <a :href="$store.state.baseUrl + $store.state.linkPrivacyPolicy" target="_blank" style="padding: 0 0 4px 4px; display: block;">{{labelPrivacyPolicy}}</a>
                </small>
              </div>
            </v-card-text>
            <!-- Actions -->
            <v-card-actions class="d-flex justify-space-between">
              <!-- Btn Cancel -->
              <v-btn color="light" @click="$router.go(-1)">{{labelBtnCancel}}</v-btn>
              <!-- Btn Submit -->
              <v-btn color="primary" :disabled="!formValid || !agreePrivacyPolicy">
                <div class="px-3" @click="submit">{{labelBtnSubmit}}</div>
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>
        <!-- Dialog -->
        <div id="send-process">
          <Dialog type="success" :status="mail.status" class="d-flex justify-center text-center" style="min-height: 90vh">
            <!-- Status Sending -->
            <template #waiting>
              <div class="pt-4">{{labelDlgWaiting}}</div>
            </template>
            <!-- Status Success -->
            <template #SuccessTitle>
              <div class="pt-4 title cssanimation fadeInTop">{{labelDlgSuccessTitle}}</div>
            </template>
            <template #SuccessDescription>
              <small v-if="form.email" class="cssanimation fadeInTop">{{labelDlgSuccessText1}}</small>
              <small v-else class="cssanimation fadeInTop">{{labelDlgSuccessText2}}</small>
            </template>
            <template #SuccessActions>
              <!-- Btn Close -->
              <v-btn class="mt-4 cssanimation fadeInTop" x-small @click="$router.go(-1)">
                {{labelDlgBtnClose}}
              </v-btn>
            </template>
          </Dialog>

          <Dialog type="success" :status="data.statusAdvert" v-if="data.statusAdvert === 2"  class="d-flex justify-center text-center" style="min-height: 90vh">
            <!-- Status Info -->
            <template #title>
              <div class="pt-2 title cssanimation fadeInTop" v-html="labelDlgSuccessTitle2"></div>
            </template>
            <template #description>
              <small class="cssanimation fadeInTop mt-2" v-if="data.dataAdvert.email">
                {{labelDlgSuccessText3}}
              </small>
              <div class="d-flex flex-column mt-4 px-4" style="width: 100%">
                <v-divider class="cssanimation fadeInTop" />
                <h4 class="cssanimation fadeInTop subtitle-2 text-center mb-3 mt-2">{{labelDlgSuccessSubTitle}}</h4>
                <div class="d-flex flex-wrap text-left" style="font-size: 12px">
                  <!-- Cod -->
                  <div class="cssanimation fadeInTop mr-4 mb-2">
                    <div class="grey-text">{{labelCodProp}}</div>
                    <b>{{data.dataAdvert.cod}}</b>
                  </div>
                  <!-- Name -->
                  <div class="cssanimation fadeInTop mr-4 mb-2">
                    <div class="grey-text">{{labelContactName}}</div>
                    <b class="text-wrap d-block" style="max-width: 300px">{{data.dataAdvert.name}}</b>
                  </div>
                  <!-- Email -->
                  <div class="cssanimation fadeInTop mr-4 mb-2" v-if="data.dataAdvert.email">
                    <div class="grey-text text-wrap">{{labelContactEmail}}</div>
                    <b class="text-wrap d-block" style="max-width: 300px">{{data.dataAdvert.email}}</b>
                  </div>
                  <!-- Phone -->
                  <div class="cssanimation fadeInTop mr-4 mb-2" v-if="!data.dataAdvert.email">
                    <div class="grey-text">{{labelContactPhone}}</div>
                    <b>{{data.dataAdvert.phone}}</b>
                  </div>
                  <!-- Date Time -->
                  <div class="cssanimation fadeInTop mr-4 mb-2">
                    <div class="grey-text">{{labelDateTime}}</div>
                    <b>{{new Date(data.dataAdvert.datetime).toLocaleDateString()}} - {{new Date(data.dataAdvert.datetime).toLocaleTimeString().substr(0,5)}} </b>
                  </div>
                </div>
                <v-divider class="cssanimation fadeInTop"  />
                <!-- Text -->
                <small class="cssanimation fadeInTop py-4 mb-2">
                  {{labelDlgSuccessText4}}
                </small>
              </div>
            </template>
            <template #actions>
              <div class="d-flex flex-column">
                <!-- Btn Waiting -->
                <v-btn class="cssanimation fadeInTop mb-4" color="primary"  small @click="() => {data.statusAdvert = 0; $router.go(-1)}">
                  {{labelDlgBtnOkWaiting}}
                </v-btn>
                <!-- Btn Again -->
                <v-btn class="cssanimation fadeInTop" small @click="data.statusAdvert = 0">
                  {{labelDlgBtnRequestAgain}}
                </v-btn>
              </div>
            </template>
          </Dialog>
        </div>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>

import systemUtils from '@/helpers/systemUtils'
import Dialog from "@/components/dialogs/Dialog";
import {actions} from "@/store/actions/contact-form";

const labelInvalidName = 'Digite um nome válido.'
const labelInvalidEmail = 'Digite um e-mail válido.'

export default {
  name: "FormPanel",
  props: ['type','business','property','data'],
  components: {Dialog},
  mixins: [systemUtils],
  data: () => ({
    formValid: false,
    labelName: '* Nome',
    labelInvalidName,
    labelPhone: '* Telefone',
    labelEmail: 'E-mail (opcional)',
    labelInvalidEmail,
    labelMessage: 'Escreva uma mensagem (opcional)',
    labelRequired: '* obrigatório',
    labelBtnCancel: 'cancelar',
    labelBtnSubmit: 'enviar',
    labelDlgWaiting: 'Enviando aguarde...',
    labelDlgSuccessTitle: 'Enviado com Sucesso!',
    labelDlgSuccessText1: 'Enviamos os detalhes da sua solicitação em seu e-mail, breve entraremos em contato.',
    labelDlgSuccessText2: 'Em breve entraremos em contato.',
    labelDlgBtnClose: 'Fechar',
    labelDateTime: 'Data/Hora',
    labelCodProp: 'Cod Imóvel',
    labelContactName: 'Nome',
    labelContactEmail: 'E-mail',
    labelContactPhone: 'Telefone',
    labelDlgSuccessTitle2: `Você já solicitou contato <br> para este imóvel`,
    labelDlgSuccessSubTitle: 'Informações da Solicitação',
    labelDlgSuccessText3: 'Sempre enviamos um comprovante de solicitação, acesse seu e-mail para verificar se você recebeu o comprovante.',
    labelDlgSuccessText4: 'Aguarde breve entraremos em contato, mas se preferir clique em \'solicitar novamente\'',
    labelDlgBtnRequestAgain: 'Solicitar Novamente',
    labelDlgBtnOkWaiting: 'Ok, Vou Aguardar',
    labelAgree: 'li e concordo com a',
    labelPrivacyPolicy: 'política de privacidade',
    agreePrivacyPolicy: false,
    form: {
      name: '',
      phone: '',
      email: '',
      message: ''
    },
    rules: {
      required: value => !!value || '',
      name: value => {
        const pattern = /([A-Za-zÀ-Úà-ù]|[A-Za-zÀ-Úà-ù]\s)+$/
        return pattern.test(value) || labelInvalidName
      },
      phone: value => {
        return value.length === 15 || value.length === 14
      },
      email: value => {
        const pattern = /^\w{0}$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || labelInvalidEmail
      },
    },
  }),
  mounted() {
    this.mail.status = 0
    this.verifyAdvert()
  },
  computed: {
    mail() {
      return this.$store.state.mail
    },
    getters() {
      return this.$store.getters
    },
    dispatch() {
      return this.$store.dispatch
    }
  },
  methods: {
    submit() {
      const data = {
        ...this.form,
        type: this.type,
        property: this.$store.state.properties.property[this.property.property].label,
        business: this.business,
        cod: this.property.cod,
        amount: this.property.price,
        address: this.property.address,
        link: this.system().getBaseHost() + this.property.link
      }

      const storeCookieAdvert = async (cod, name, email, phone) => {
        this.dispatch(actions.GET_CHECKIN_ADVERT, {cod, name, email, phone})
        .then(data => {
          this.$cookies.set('CHECKIN_ADVERT_COD_'+this.property.cod, data.token, data.expire)
        })
      }

      this.dispatch('mail/send', {query: this.type, data, callback : storeCookieAdvert})


    },
    verifyAdvert() {
      const cookieKey = 'CHECKIN_ADVERT_COD_'+this.property.cod

      if(this.$cookies.isKey(cookieKey)) {

        const cookieData = this.$cookies.get(cookieKey)

        this.dispatch(actions.GET_VERIFY_ADVERT, {token: cookieData})

      }
    }
  },
  watch: {
    ['form.name']() {
      this.form.name = this.system().capitalize(this.form.name)
    }
  }
}
</script>
