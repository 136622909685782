import axios from "axios";

function properties() {
  // axios.defaults.withCredentials = true

  return {
    namespaced: true,
    state: {
      data: [],
      error: null,
      status: 0,
      headerPanelForRent: {
        title: "aluga-se",
        subtitle: "detalhes",
      },
      headerPanelForSale: {
        title: "vende-se",
        subtitle: "detalhes",
      },
      specifications: {
        ground: {
          label: "m² terreno",
          icon: require("@/assets/svg/ground.svg"),
        },
        build: { label: "m² área", icon: require("@/assets/svg/build.svg") },
        bedroom: {
          label: "dormitório",
          icon: require("@/assets/svg/bedroom.svg"),
        },
        suite: { label: "suíte", icon: require("@/assets/svg/suite.svg") },
        bathroom: {
          label: "banheiro",
          icon: require("@/assets/svg/bathroom.svg"),
        },
        garage: { label: "garagem", icon: require("@/assets/svg/garage.svg") },
        room: { label: "sala", icon: require("@/assets/svg/room.svg") },
        kitchen: {
          label: "cozinha",
          icon: require("@/assets/svg/kitchen.svg"),
        },
        diningroom: {
          label: "copa",
          icon: require("@/assets/svg/diningroom.svg"),
        },
        terrace: {
          label: "varanda",
          icon: require("@/assets/svg/terrace.svg"),
        },
        laundry: {
          label: "lavanderia",
          icon: require("@/assets/svg/laundry.svg"),
        },
        office: {
          label: "home office",
          icon: require("@/assets/svg/office.svg"),
        },
      },
      business: {
        1: { label: "aluga-se" },
        2: { label: "vende-se" },
      },
      property: {
        1: { label: "casa" },
        2: { label: "apto." },
        3: { label: "prédio comercial" },
        4: { label: "terreno" },
        5: { label: "cômodo comercial" },
      },
    },
    getters: {
      getBusiness(state) {
        return (itemIndex) => {
          const business = state.business[itemIndex];
          return business ? business.label : "";
        };
      },
      getProperty(state) {
        return (itemIndex) => {
          const property = state.property[itemIndex];
          return property ? property.label : "";
        };
      },
    },
    actions: {
      async getProperties({ state, rootState }, { business }) {
        state.status = 1;

        let query = "";
        if (business) query = "?business=" + business;

        const result = await axios
          .get(rootState.baseUrl + "api/properties" + query)
          .catch((err) => {
            state.status = -1;
            const resultError = err.response?.data;
            if (resultError?.errors) state.error = resultError;

            if (err.response?.status === 404) {
              console.log("redirecionando...");
              sessionStorage.setItem("redirect", "true");
              location.href = "/";
            }
          });

        if (result && result.data) {
          state.data = result.data.content.data;
        }

        state.status = 0;
      },
      async getProperty({ state, rootState }, { cod }) {
        state.status = 1;

        const result = await axios
          .get(rootState.baseUrl + "api/properties/" + cod)
          .catch((err) => {
            state.status = -1;
            const resultError = err.response?.data;
            if (resultError?.errors) state.error = resultError;

            if (err.response?.status === 404) {
              console.log("redirecionando...");
              sessionStorage.setItem("redirect", "true");
              location.href = "/";
            }
          });

        if (result && result.data) {
          state.data = [result.data.content];
        }

        state.status = 0;
        console.log(state.status);
      },
    },
  };
}

export default properties();
