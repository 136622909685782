<template>
  <div class="about-panel mb-2">
    <v-card class="pa-md-4">
      <!-- Sections -->
      <v-row class="no-gutters" v-for="(item, i) in data" :key="i">
        <!-- Img -->
        <v-col cols="12"  xs="12" md="3">
          <div>
            <v-img :src="item.img" aspect-ratio="1.4" position="100%"  />
          </div>
        </v-col>
        <!-- Description -->
        <v-col class="mb-3">
          <div class="pl-2 mx-2 body-1 d-flex" style="max-width: 520px">
            {{item.desc}}
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AboutPanel",
  props: ['data']
}
</script>
