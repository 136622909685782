import axios from "axios";

function contactForm () {

    // axios.defaults.withCredentials = true

    return {
        namespaced: true,
        state: {
            title: 'Contato',
            subtitle: 'Preencha o formulário e clique em \'enviar\'',
            statusAdvert: 0,
            errorAdvert: null,
            dataAdvert: null
        },
        getters: {
            getTitle: state => type => state.title + (type === 'rent' ? ' - alugar' : type === 'sale' ? ' - comprar' : type === 'visit' ? ' - visitar' : ''),
            hasBusiness() {
                return value => ['rent','sale','visit'].includes(value)
            }
        },
        actions: {
            async getCheckinAdvert({state, rootState}, {cod, name, email, phone}) {

                state.statusAdvert = 1

                const result = await axios.post(rootState.baseUrl + 'api/checkinAdvert', {cod, name, email, phone}).catch(err => {
                    const resultError = err.response.data
                    if(resultError.errors)
                        state.errorAdvert = resultError

                    state.statusAdvert = -1

                    if(err.response.status === 404) {
                        console.log('redirecionando...')
                        sessionStorage.setItem('redirect','true')
                        location.href = '/'
                    }
                })

                if(result && result.data) {
                    state.dataAdvert = result.data.content
                    state.statusAdvert = 0
                }

                return new Promise((resolve, reject) => {resolve(state.dataAdvert); reject(state.errorAdvert)})
            },
            async getVerifyAdvert({state, rootState}, {token}) {

                state.statusAdvert = 1
                state.dataAdvert = null

                const result = await axios.get(rootState.baseUrl + 'api/verifyAdvert' + `?token=${token}`).catch(err => {
                    const resultError = err.response.data
                    if(resultError.errors)
                        state.errorAdvert = resultError

                    state.statusAdvert = -1

                    if(err.response.status === 404) {
                        console.log('redirecionando...')
                        sessionStorage.setItem('redirect','true')
                        location.href = '/'
                    }
                })

                if(result && result.data) {
                    state.dataAdvert = result.data.content
                    state.statusAdvert = 2
                }
            }
        }
    }
}

export default contactForm()
