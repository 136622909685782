<template>
  <div class="about fill-height transparent flex-fill px-3">
    <HeaderPanel :data="store.homeHeader" />
    <BackButton label="Home" to="/" v-if="$vuetify.breakpoint.smAndDown" />
    <AboutPanel :data="store.descriptions" />
  </div>
</template>

<script>
import HeaderPanel from "@/components/headers/HeaderPanel";
import AboutPanel from "@/components/contents/AboutPanel";
import BackButton from "@/components/buttons/BackButton";
export default {
  name: "About",
  components: {BackButton, AboutPanel, HeaderPanel},
  computed: {
    store() {
      return  this.$store.state.about
    }
  }
}
</script>

