<template>
  <v-app>
    <v-main>
      <!-- Background Image -->
      <v-img class="home-bg-img" :src="store.home.bgImg" />
      <!-- LayoutVertical -->
      <LayoutVertical :data="store" v-if="$vuetify.breakpoint.smAndDown"/>
      <!-- LayoutHorizontal -->
      <LayoutHorizontal :data="store" v-if="$vuetify.breakpoint.mdAndUp" />
    </v-main>
  </v-app>
</template>

<script>
import LayoutHorizontal from "./LayoutHorizontal";
import LayoutVertical from "@/components/layouts/LayoutVertical";
export default {
  name: "LayoutDefault",
  components: {LayoutVertical, LayoutHorizontal},
  computed: {
    store() {
      return this.$store.state
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/sass/main";
</style>
