<template>
  <div
    class="house-list d-flex justify-lg-center flex-column mx-3 p-3"
    style="min-width: 95%"
  >
    <HeaderPanel :data="headerPanel" />
    <BackButton label="Home" to="/" v-if="$vuetify.breakpoint.smAndDown" />
    <div class="house-list-container pr-sm-2">
      <v-card v-if="empty" class="house-list-card pa-4" height="88vh"
      style="overflow: hidden;"
      >
        <v-row>
          <v-col cols="12" class="display-1 pt-8">No momento não há imóveis disponíveis</v-col>
          <v-col cols="12" class="pt-4" style="background-image: url('/img/background-house.png'); 
          height: 78vh;
          background-size: 800px 800px;
          filter: opacity(0.26);
          background-position-x: center;"></v-col>
          <v-col cols="12"><v-divider class="my-2" /></v-col>
        </v-row>
      </v-card>
      <v-card
        class="house-list-card mb-4"
        v-for="(item, i) in sortedArray"
        :key="i"
      >
        <v-row class="no-gutters">
          <v-col
            xs="12"
            sm="5"
            class="house-list-card-col-left d-flex flex-column justify-center"
          >
            <div
              class="
                house-list-card-carousel
                d-flex
                align-center
                justify-center
                flex-column
              "
            >
              <v-progress-circular
                indeterminate
                v-if="$store.state.properties.status === 1"
              />
              <v-carousel
                v-if="$store.state.properties.status === 0"
                hide-delimiter-background
                hide-delimiters
                show-arrows-on-hover
              >
                <v-carousel-item
                  v-for="(img, j) in Array(item.imgs).fill(null)"
                  :key="j"
                  :src="`${$store.state.baseUrlResources}resources/images/cod_${
                    item.cod
                  }/${j + 1}.jpg`"
                />
              </v-carousel>
            </div>
          </v-col>
          <v-col
            xs="12"
            sm="7"
            class="house-list-card-col-right d-flex flex-column flex-fill"
          >
            <div
              class="
                house-list-card-details
                d-flex
                flex-column flex-fill
                justify-space-between
              "
            >
              <div>
                <div class="d-flex">
                  <h6 class="text-uppercase grey-text">
                    {{ getters["properties/getBusiness"](item.business) }}
                    {{ getters["properties/getProperty"](item.property) }}
                  </h6>
                  <h6 class="house-list-card-details-pill text-uppercase">
                    {{ labelCod }} {{ item.cod }}
                  </h6>
                  <h6
                    v-if="getOptions(item.options).order"
                    class="
                      house-list-card-details-pill--destaque
                      text-uppercase
                    "
                  >
                    {{ labelHightLights }}
                  </h6>
                </div>
                <h3 class="title text-truncate">{{ item.title }}</h3>
                <div>
                  <small class="grey-text">{{ item.address }}</small>
                </div>
                <div class="mt-3">
                  <small>
                    <HouseSpecification
                      :data="JSON.parse(item.specifications).data"
                      :props="JSON.parse(item.brief_specifications).data"
                    />
                  </small>
                </div>
                <div
                  class="house-list-card-details-text mt-3 d-block d-sm-none"
                >
                  <small v-html="item.description" />
                </div>
              </div>
              <small v-if="overflowText[i]"
                >...<i>para ver mais, clique em 'ver detalhes'</i></small
              >
              <div class="mt-6 d-flex align-center justify-space-between">
                <v-row class="no-gutters">
                  <v-col sm="12" md="6" class="mr-3">
                    <h1
                      v-if="!requestPrice(item.business, item.price)"
                      class="font-weight-medium text-no-wrap text-left"
                    >
                      {{ item.price | currency }}
                      <small v-if="item.business === 1" class="title">{{
                        labelPerMonth
                      }}</small>
                    </h1>
                    <h2
                      v-else
                      class="font-weight-medium text-no-wrap text-left"
                    >
                      {{ labelRequestPrice }}
                    </h2>
                  </v-col>
                  <v-col class="text-md-right">
                    <v-btn
                      :to="`detalhes/${item.cod}`"
                      outlined
                      color="primary"
                      >{{ labelBtnShowDetails }}</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import HouseSpecification from "@/components/contents/HouseSpecification";
import HeaderPanel from "@/components/headers/HeaderPanel";
import BackButton from "@/components/buttons/BackButton";
export default {
  name: "HouseList",
  components: { BackButton, HeaderPanel, HouseSpecification },
  props: ["data", "store", "headerPanel", "empty"],
  data: () => ({
    labelCod: "cod",
    labelPerMonth: "/mês",
    labelBtnShowDetails: "ver detalhes",
    labelRequestPrice: "R$... valor sob consulta",
    labelHightLights: "destaque",
    overflowText: [],
  }),
  computed: {
    getters() {
      return this.$store.getters;
    },
    requestPrice() {
      return (business, value) =>
        (business === 2 || business === 1) && value === -1;
    },
    sortedArray() {
      const compare = (a, b) => {
        return b.options
          ? this.getOptions(a.options).beauty -
              this.getOptions(b.options).beauty ||
              this.getOptions(a.options).order -
                this.getOptions(b.options).order
          : -1;
      };

      return this.data.slice().sort(compare);
    },
  },
  methods: {
    getOptions(opt) {
      return opt ? JSON.parse(opt) : {};
    },
    setOverflowText() {
      setTimeout(() => {
        const el = document.querySelectorAll(".house-list-card-details-text");
        this.overflowText = Array(el.length).fill(null);
        if (el) {
          el.forEach((e, index) => {
            this.overflowText.splice(index, 1, e.clientHeight >= 200)
          });
        }
      }, 400);
    },
  },
  mounted() {
    this.setOverflowText();
  }
};
</script>
