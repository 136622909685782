<template>
  <div class="dialog" v-if="status > 0">
    <!-- Status Waiting -->
    <div class="d-flex flex-column justify-center align-center" v-if="status === 1">
      <v-progress-circular indeterminate />
      <slot name="waiting"></slot>
    </div>
    <!-- Status Success -->
    <div class="d-flex justify-center flex-column align-center" style="margin-top: -80px" v-if="status === 2">
      <!-- Animation -->
      <div style="max-width: 200px; max-height: 150px">
        <lottie :options="lottieOptions" :height="200" :width="200" />
      </div>
      <!-- Default -->
      <slot name="title"></slot>
      <slot name="description"></slot>
      <slot name="actions"></slot>
      <!-- Success -->
      <slot name="SuccessTitle"></slot>
      <slot name="SuccessDescription"></slot>
      <slot name="SuccessActions"></slot>
      <!-- Warning -->
      <slot name="WarnTitle"></slot>
      <slot name="WarnDescription"></slot>
      <slot name="WarnActions"></slot>
      <!-- Error -->
      <slot name="ErrorTitle"></slot>
      <slot name="ErrorDescription"></slot>
      <slot name="ErrorActions"></slot>
    </div>
  </div>
</template>

<script>

import Lottie from 'vue-lottie';

export default {
  name: "Dialog",
  props: ['status','type'],
  components: {Lottie},
  computed: {
    lottieOptions() {
      switch (this.type) {
        case 'success': return {animationData: require("@/plugins/lottie-animations/success.json"), autoplay: true, loop: false}
        case 'warn': return {}
        case 'error': return {}
        default: return {animationData: {}, autoplay: true, loop: false}
      }
    }
  }
}
</script>
