import axios from "axios";

function mail() {

    // axios.defaults.withCredentials = true

    return {
        namespaced: true,
        state: {
            data: [],
            error: null,
            status: 0
        },
        actions: {
            async send({state, rootState},{query, data, callback}) {

                state.status = 1

                data = {
                    propType: data.property,
                    propCod: data.cod,
                    propBusiness: data.business,
                    propAmount: data.amount,
                    propAddress: data.address,
                    propLink: data.link,
                    contactName: data.name,
                    contactPhone: data.phone,
                    contactEmail: data.email,
                    contactMessage: data.message
                }

                const result = await axios.post(rootState.baseUrl + 'api/sendEmail/' + query, data).catch(err => {
                    const resultError = err.response.data
                    if(resultError.errors)
                        state.error = resultError

                    state.status = -1

                    if(err.response.status === 404) {
                        console.log('redirecionando...')
                        sessionStorage.setItem('redirect','true')
                        location.href = '/'
                    }
                })

                if(result && result.data) {
                    state.status = 2

                    if(typeof callback === 'function') {
                        callback(data.propCod, data.contactName, data.contactEmail, data.contactPhone)
                    }
                }
            }
        }
    }

}

export default mail()
