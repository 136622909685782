<template>
  <header class="header-app primary d-flex justify-space-between fill-height align-center pa-3 white--text">
    <!-- Logo -->
    <div class="header-app-logo">
      <v-img :src="data.logo" />
    </div>
    <!-- Title -->
    <div class="header-app-title">
      <h1 class="headline text-uppercase">{{data.title}}</h1>
    </div>
    <!-- Button Menu -->
    <div class="header-app-btn-menu">
      <div class="btn-menu-container d-inline-block pa-1"
           @click.stop="data.drawer = !data.drawer">
        <v-icon color="white">mdi-menu</v-icon>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderApp",
  props: ['data']
}
</script>
