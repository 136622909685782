function sidebarApp() {
    return {
        state: {
            drawer: true,
            logo: require('@/assets/img/logo_vertical_colorido.jpg'),
            captionLogo: 'CRECI-SP 177675F',
            subtitle: 'Seu imóvel em boas mãos',
            menu: [
                {label:'home', link: '/'},
                // {label:'aluga-se', link: '/aluga-se'},
                // {label:'vende-se', link: '/vende-se'},
                // {label:'anúnciar imóvel', link: '#',},
                {label:'documentos', link: '/documentos'},
                {label:'sobre', link: '/sobre'}
            ],
            menuFooter: [
                {label: '(16) 99996-9100', icon: 'mdi-whatsapp'},
                {label: '(16) 3722-9001', icon: 'mdi-phone'},
                {label: 'contato@furiniimoveis.com.br', icon: 'mdi-email'},
                {label: 'Rua Dr. Alcindo Ribeiro Conrado, 1232, Centro, Franca-SP', icon: 'mdi-map-marker-outline'},
            ]
        },
    }
}

export default sidebarApp()
