<template>
  <v-row class="house-specification d-flex no-gutters">
    <v-col cols="6" sm="6" md="5" lg="3" v-for="(prop, i) in props" :key="i" class="pa-2 mr-md-2">
      <div class="d-flex align-center flex-nowrap">
        <div style="width: 34px; height: 34px" class="mr-2">
          <v-img :src="specifications(prop).icon" width="34" />
        </div>
        <b>
          {{specifications(prop).value | decimalToString}}
          {{specifications(prop).label}}
        </b>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HouseSpecification",
  props: ['data','props'],
  computed: {
    store() {
      return this.$store.state.properties
    },
  },
  methods: {
    specifications(prop) {
      return ({...this.data[prop],...this.store.specifications[prop]})
    }
  }
}
</script>

