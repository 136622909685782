
function documents() {
    return {
        namespaced: true,
        state: {
            homeHeader: {title: 'documentos'},
            title: ' Documentos e fichas de cadastro para locação',
            subtitle: 'Você poderá baixar os arquivos que contém os documentos com as fichas para preencher as informações necessárias para cadastro de locação',
            files: [
                {desc: 'Relação de documentos para locação', link: 'api/download/RELACAO_DE_DOCUMENTOS_PARA_LOCACAO.pdf'},
                {desc: 'FICHA CADASTRAL PESSOA FISICA LOCATÁRIO', link: 'api/download/FICHA_CADASTRAL_PESSOA_FISICA_LOCATARIO.pdf'},
                {desc: 'FICHA CADASTRAL FIADOR AVALISTA', link: 'api/download/FICHA_CADASTRAL_FIADOR_AVALISTA.pdf'}
            ]
        }
    }
}

export default documents()
