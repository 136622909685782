
function imageSlide() {
    return {
        state: {
            showOverlay: true,
        }
    }
}

export default imageSlide()
