
function headerApp() {
    return {
        state: {
            drawer: false,
            title: '',
            logo: require('@/assets/img/logo_mini.jpg')
        }
    }
}

export default headerApp()
