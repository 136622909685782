<template>
  <div class="home-panel-2 d-block">
    <div class="home-panel-container d-flex flex-fill flex-row
     flex-wrap justify-center align-center fill-height">

      <div ref="panel1" class="cssanimation home-panel-2-card">
        <div ref="card1" class="cssanimation fadeInBottom">
          <v-card class="border-bottom-right-radius-0 border-bottom-left-radius-0 border-top-right-radius-0">
            <v-card-text class="d-flex flex-column justify-center align-center fill-height text-center">
              <span ref="text1" class="subtitle-1 motion-text-card-1">{{ texts[0] }}</span>
            </v-card-text>
          </v-card>
        </div>
        <div ref="img1" id="home-panel-2-card-img1" class="cssanimation fadeIn home-panel-2-card-img">
          <v-img :src="imgs[0]" />
        </div>
      </div>

      <div ref="panel2" class="cssanimation home-panel-2-card">
        <div ref="card2" class="cssanimation fadeInTop">
          <v-card class="border-radius-all-0">
            <v-card-text class="d-flex flex-column justify-center align-center fill-height text-center">
              <span ref="text2" class="subtitle-1 motion-text-card-2">{{ texts[1] }}</span>
            </v-card-text>
          </v-card>
        </div>
        <div ref="img2" id="home-panel-2-card-img2" class="cssanimation fadeIn home-panel-2-card-img">
          <v-img :src="imgs[1]" />
        </div>
      </div>

      <div ref="panel3" class="cssanimation home-panel-2-card">
        <div ref="card3" class="cssanimation fadeInBottom">
          <v-card class="border-radius-all-0">
            <v-card-text class="d-flex flex-column justify-center align-center fill-height text-center">
              <span ref="text3" class="subtitle-1 motion-text-card-3">{{ texts[2] }}</span>
            </v-card-text>
          </v-card>
        </div>
        <div ref="img3" id="home-panel-2-card-img3" class="cssanimation fadeIn home-panel-2-card-img">
          <v-img :src="imgs[2]" />
        </div>
      </div>

      <div ref="panel4" class="cssanimation home-panel-2-card">
        <div ref="card4" class="cssanimation fadeInTop">
          <v-card class="border-top-left-radius-0 border-top-right-radius-0 border-bottom-left-radius-0">
            <v-card-text class="d-flex flex-column justify-center align-center fill-height text-center">
              <span ref="text4" class="subtitle-1 motion-text-card-4">{{ texts[3] }}</span>
            </v-card-text>
          </v-card>
        </div>
        <div ref="img4" id="home-panel-2-card-img4" class="cssanimation fadeIn home-panel-2-card-img">
          <v-img :src="imgs[3]" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// Home do Mobile temporaria, até que pessam para reativar Alugar e Vender
export default {
  name: "HomePanel",
  data: () => ({
    imgs: [
      require('@/assets/img/img_home_panel_1.jpg'),
      require('@/assets/img/img_home_panel_2.jpg'),
      require('@/assets/img/img_home_panel_3.jpg'),
      require('@/assets/img/img_home_panel_4.jpg'),
    ],
    texts: [
      'Abordagem personalizada',
      'Compromisso com a excelência',
      'Profissionalismo',
      'Supere as expectativas'
    ]
  }),
  mounted() {
    // this.ccsAnimationMotion()
  },
  methods: {
    ccsAnimationMotion() {
      const {
        img1, img2, img3, img4,
        card1, card2, card3, card4,
        text1, text2, text3, text4,
        panel1, panel2, panel3, panel4
      } = this.$refs
      const vImgs = [img1, img2, img3, img4]
      const vCards = [card1, card2, card3, card4]
      const vPanels = [panel1, panel2, panel3, panel4]
      const vTexts = [text1, text2, text3, text4]

      const motion = (type, duration, callback) => {
        if (type === 'timeout') {
          const __timer__out = setTimeout(() => { callback(__timer__out, clearTimeout) }, duration)
        }
        if (type === 'interval') {
          const __timer__int = setInterval(() => { callback(__timer__int, clearInterval) }, duration)
        }
      }

      fadeInImagesAndCardsCascade()


      function fadeInImagesAndCardsCascade() {
        vImgs.map((img, i) => {
          motion('timeout', 466 * (i + 1), (timer, clear) => {
            img.classList.remove('d-none')
            vCards[i].classList.remove('d-none')
            clear(timer)
            if (i === 3) elevationScaleCascade()
          })
        })

      }

      function elevationScaleCascade() {
        let i = 0
        motion('interval', 3000, () => {

          if (i === 0) {
            vPanels.map(e => e.classList.remove('elevationScaleOut'))
            vPanels[i].classList.add('elevationScaleIn');
            vTexts[i].classList.add('motion-text-card-' + (i + 1));
          } else if (i > 0) {
            vPanels[i - 1].classList.add('elevationScaleOut')
            vPanels[i - 1].classList.remove('elevationScaleIn');
            if (i < 4) {
              vPanels[i].classList.add('elevationScaleIn');
              vTexts[i].classList.add('motion-text-card-' + (i + 1));
            }
          }

          if (i < 4) {
            i = i + 1
          } else {
            i = 0
            blurInCascade()
            vTexts.map((e, i) => e.classList.remove('motion-text-card-' + (i + 1)))
          }

        })
      }

      function blurInCascade() {
        vImgs.map((img, i) => {
          motion('timeout', 90 * (i + 1), (timer, clear) => {
            img.classList.add('cssanimation', 'blurIn')
            clear(timer)
            if (i === 3) motion('timeout', 500, () => vImgs.map(img => img.classList.remove('cssanimation', 'blurIn')))
          })
        })
      }
    }
  }
}
</script>
