<template>
  <div class="back-button text-left">
    <v-btn small class="mb-2 primary--text" :to="to || ''">
      <v-icon size="20" class="pr-2">mdi-arrow-left</v-icon>
      {{label}}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "BackButton",
  props: ['label','to']
}
</script>

