
function sidebarRight() {
    return {
        state: {
            instagram: 'https://www.instagram.com/furini.gestor.imobiliario/',
            facebook: 'https://www.facebook.com/people/Furini-Gestor-Imobili%C3%A1rio/100011369772140',
            whatsappImg: require('@/assets/img/WhatsApp.png')
        }
    }
}

export default sidebarRight()
