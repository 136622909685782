import Vue from 'vue'
import Vuex from 'vuex'
import sidebarApp from "@/store/modules/sidebar-app";
import home from "@/store/modules/home";
import headerApp from "@/store/modules/header-app"
import sidebarRightApp from "@/store/modules/sidebar-right"
import imageSlide from "@/store/modules/image-slide"
import properties from "@/store/modules/properties";
import about from "@/store/modules/about"
import mail from "@/store/modules/mail"
import documents from "@/store/modules/documents"
import contactForm from "@/store/modules/contact-form"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:8000/backoffice/' : process.env.NODE_ENV === 'remote' ? 'http://192.168.12.5:8000/backoffice/' : '/backoffice/',
    baseUrlResources: process.env.NODE_ENV === 'development' ? 'http://localhost:8000/' : process.env.NODE_ENV === 'remote' ? 'http://192.168.12.5:8000/' : '/',
    ulrWhatsApp: 'https://api.whatsapp.com/send?phone=5516999969100&fbclid=IwAR3GCqiaU3Uz08h7GscUgeynLy2TjN3i6fjxUBcbniCP65exLro-4zpbFug',
    linkPrivacyPolicy: 'api/docs/Politica_de_Privacidade_Furini_Gestor_Imobiliario.pdf'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    headerApp,
    sidebarApp,
    sidebarRightApp,
    home,
    properties,
    imageSlide,
    about,
    mail,
    documents,
    contactForm
  }
})
