<template>
  <div class="contact-form">
    <FormPanel :data="store" :type="type" :business="business" :property="property" />
  </div>
</template>

<script>

import FormPanel from "@/components/contents/FormPanel";

export default {
  name: "ContactForm",
  components: {FormPanel},
  props: ['type','business','property'],
  beforeRouteEnter(to, from, next) {

    function hasBusiness(value) {
      return ['rent','sale','visit'].includes(value)
    }

    if(hasBusiness(to.params.type)) next()
    else
      next(_this => _this.$router.go(-1))
  },
  computed: {
    store() {
      return this.$store.state.contactForm
    },
    propertySelected() {
      return this.$store.state.properties.propertySelected
    },
    getters() {
      return this.$store.getters
    }
  }
}
</script>
