import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutDefault from "@/components/layouts/LayoutDefault";
import Home from "@/views/Home";
import ForRent from "@/views/ForRent";
import ForSale from "@/views/ForSale";
import SubPage from "@/components/contents/SubPage";
import ForRentDetails from "@/views/ForRentDetails";
import ForSaleDetails from "@/views/ForSaleDetails";
import ImageSlide from "@/components/contents/ImageSlide";
import About from "@/views/About";
import ContactForm from "@/views/ContactForm";
import Documents from "@/views/Documents";
import NotFound from "@/views/NotFound";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: LayoutDefault,
    children: [
      { path: '', component: Home, name: 'Home', meta: { title: 'Furini Gestor Imobiliário' }, },
      {
        path: 'aluga-se', redirect: 'aluga-se/lista', component: SubPage, meta: { title: 'Aluga-se : Furini Gestor Imobiliário' },
        children: [
          { path: 'lista', component: ForRent, name: 'ForRent' },
          {
            path: 'detalhes/:id', component: ForRentDetails, name: 'ForRentDetails',
            children: [
              { path: 'slide', component: ImageSlide, name: 'ForRentDetailsImageSlide', props: true },
              { path: 'contato', component: ContactForm, name: 'ContactFormRent', props: true },
            ]
          }
        ]
      },
      {
        path: 'vende-se', redirect: 'vende-se/lista', component: SubPage, meta: { title: 'Vende-se : Furini Gestor Imobiliário' },
        children: [
          { path: 'lista', component: ForSale, name: 'ForSale' },
          {
            path: 'detalhes/:id', component: ForSaleDetails, name: 'ForSaleDetails',
            children: [
              { path: 'slide', component: ImageSlide, name: 'ForSaleDetailsImageSlide', props: true },
              { path: 'contato', component: ContactForm, name: 'ContactFormSale', props: true },
            ]
          },
        ]
      },
      { path: 'sobre', component: About, meta: { title: 'Sobre : Furini Gestor Imobiliário' } },
      { path: 'documentos', component: Documents, meta: { title: 'Documentos : Furini Gestor Imobiliário' } },
      {
        path: 'admin', meta: { title: 'Admin : Furini Gestor Imobiliário' },
        children: [
          { path: 'login', meta: { title: 'Login : Furini Gestor Imobiliário' } },
          { path: '' }
        ]
      },
      {
        path: '/:pathMatch(.*)*', component: NotFound, props: true,
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*', component: LayoutDefault,
    children: [
      { path: 'erro-404', name: 'NotFound', component: NotFound, props: true }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!from.name && (to.name && to.name !== 'Home')) {
    sessionStorage.setItem('redirectTo', to.fullPath)
  }
  next()
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
