<template>
  <div class="layout-horizontal">
    <div class="fill-height d-flex primary">
      <!-- Col Left -->
      <div class="col-left cssanimation fadeInLeft ">
        <SidebarApp :data="data.sidebarApp" :store="data"/>
      </div>
      <!-- Col Middle -->
      <div class="col-middle d-flex flex-fill flex-column">
        <router-view />
        <PrivacyPolicy :is-mobile="false" />
      </div>
      <!-- Col Right -->
      <div class="col-right">
        <SidebarRightApp :data="data.sidebarRightApp" />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarRightApp from "@/components/sidebars/SidebarRightApp";
import SidebarApp from "@/components/sidebars/SidebarApp";
import PrivacyPolicy from "@/components/contents/PrivacyPolicy";

export default {
  name: "LayoutHorizontal",
  components: {PrivacyPolicy, SidebarRightApp, SidebarApp},
  props: ['data']
}
</script>
