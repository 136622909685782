
function home() {
    return {
        state: {
            bgImg: require('@/assets/img/imagem_fundo_home.jpg'),
            homeHeader: {title: 'compra - venda - locação'},
        }
    }
}

export default home()
