<template>
  <div class="social-media">
    <v-btn link icon class="mr-3" @click="() => openLink(data.instagram)">
      <v-icon class="cssanimation fadeInRight" color="white">mdi-instagram</v-icon>
    </v-btn>
    <v-btn link icon @click="() => openLink(data.facebook)">
      <v-icon class="cssanimation fadeInLeft" color="white">mdi-facebook</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SocialMedia",
  props: ['data'],
  methods: {
    openLink(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
