<template>
  <div class="for-rent-details fill-height transparent flex-fill d-flex justify-center">
    <HouseDetails  :header-panel="store.headerPanelForRent" :data="store.data.find(e => e.cod === this.$router.currentRoute.params.id)" :store="store"/>
  </div>
</template>

<script>
import HouseDetails from "@/components/contents/HouseDetails";
import {actions} from "@/store/actions/properties";
export default {
  name: "ForRentDetails",
  components: {HouseDetails},
  computed: {
    store() {
      return this.$store.state.properties
    }
  },
  mounted() {
    this.$store.dispatch(actions.GET_PROPERTY, {cod: this.$router.currentRoute.params.id})
  }
}
</script>
