<template>
  <div
    class="
      house-details
      d-flex
      justify-lg-center
      mx-2
      flex-fill
      d-flex
      flex-column
    "
  >
    <router-view :data="data" />
    <HeaderPanel :data="headerPanel" subtitle="true" />
    <BackButton
      :label="labelBtnBack"
      :to="data.business === 1 ? '/aluga-se' : '/vende-se'"
    />
    <div class="house-details-container flex-fill mb-1 mb-md-6 pr-sm-2">
      <!-- Row Top -->
      <v-card class="house-details-card m-0 p-0">
        <v-row class="no-gutters">
          <v-col class="house-details-card-row-top d-flex flex-row">
            <v-row class="no-gutters">
              <v-col xs="12" sm="6">
                <div class="house-list-card-carousel d-flex flex-row flex-fill">
                  <v-carousel
                    hide-delimiter-background
                    hide-delimiters
                    show-arrows-on-hover
                  >
                    <v-carousel-item
                      v-for="(img, i) in Array(data.imgs).fill(null)"
                      cover
                      :key="i"
                      :eager="true"
                      :src="`${
                        $store.state.baseUrlResources
                      }resources/images/cod_${data.cod}/${i + 1}.jpg`"
                      :to="toLinkImageSlide(i)"
                      :ripple="false"
                    >
                      <slot>
                        <div
                          class="
                            cssanimation
                            fadeIn
                            d-flex
                            justify-center
                            align-center
                          "
                          style="height: 100%; position: relative; z-index: -2"
                        >
                          <v-progress-circular indeterminate color="black" />
                        </div>
                      </slot>
                    </v-carousel-item>
                  </v-carousel>
                </div>
              </v-col>
              <v-col
                class="d-none d-sm-flex align-center"
                style="background-color: #efefef"
              >
                <div
                  class="
                    house-list-card-img-panel
                    d-none d-sm-flex
                    flex-fill flex-wrap
                  "
                  style="position: relative"
                  @mouseleave="onMouseLeaveImages"
                >
                  <v-row no-gutters>
                    <v-col
                      v-for="(img, i) in Array(data.imgs).fill(null)"
                      :key="i"
                      class="d-flex"
                    >
                      <v-img
                        height="136"
                        width="136"
                        :src="`${
                          $store.state.baseUrlResources
                        }resources/images/cod_${data.cod}/${i + 1}.jpg`"
                        @mouseover="onMouseOverImages"
                        @click="() => $router.push(toLinkImageSlide(i))"
                      >
                        <slot name="placeholder">
                          <div
                            class="
                              cssanimation
                              fadeIn
                              d-flex
                              justify-center
                              align-center
                            "
                            style="
                              height: 100%;
                              position: relative;
                              z-index: -2;
                            "
                          >
                            <v-progress-circular indeterminate color="black" />
                          </div>
                        </slot>
                      </v-img>
                    </v-col>
                  </v-row>
                  <v-btn
                    class="cssanimation house-list-card-img-panel-btn-see-all"
                    :class="!showBtnAll ? 'fadeOutBottom' : 'fadeInBottom'"
                    @click="() => $router.push(toLinkImageSlide())"
                    outlined
                    v-if="true"
                    style="
                      position: absolute;
                      z-index: 3;
                      right: 6px;
                      bottom: 6px;
                      box-shadow: 0 0 20px 20px #ffffffc7 inset;
                    "
                  >
                    <v-icon class="mr-3">mdi-image-multiple-outline</v-icon>
                    Ver todos
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Row Bottom -->
        <v-row class="no-gutters mt-0">
          <v-col
            class="house-details-card-row-bottom d-flex flex-column flex-fill"
          >
            <div
              class="
                house-details-card-details
                d-flex
                flex-column flex-fill
                justify-space-between
              "
            >
              <v-row class="no-gutters">
                <v-col
                  lg="8"
                  class="px-5 mt-3 d-flex flex-column justify-space-between"
                >
                  <div>
                    <div class="d-flex">
                      <h6 class="text-uppercase grey-text">
                        {{ getters["properties/getProperty"](data.property) }}
                      </h6>
                      <h6
                        class="house-details-card-details-pill text-uppercase"
                      >
                        {{ labelCod }} {{ data.cod }}
                      </h6>
                      <h6
                        v-if="getOptions(data.options).order"
                        class="
                          house-list-card-details-pill--destaque
                          text-uppercase
                        "
                      >
                        {{ labelHightLights }}
                      </h6>
                    </div>
                    <h3 class="title">{{ data.title }}</h3>
                    <div>
                      <small class="grey-text">{{ data.address }}</small>
                    </div>
                    <small class="mt-3 d-flex">
                      <HouseSpecification
                        :data="JSON.parse(data.specifications).data"
                        :props="
                          Object.keys(JSON.parse(data.specifications).data)
                        "
                      />
                    </small>
                    <div class="my-3">
                      <small v-html="data.description" />
                    </div>
                  </div>
                </v-col>
                <v-col lg="4">
                  <div
                    class="
                      house-details-card-details-right-panel
                      d-flex
                      flex-column
                      pa-3
                      ma-sm-3
                      justify-start
                    "
                  >
                    <h6 class="text-uppercase grey-text">
                      {{
                        getters["properties/getBusiness"](data.business)
                          | translateBusiness
                      }}
                    </h6>
                    <span>
                      <small
                        v-if="getOptions(data.options).prevPrice"
                        class="subtitle-1 text-decoration-line-through"
                        >{{
                          getOptions(data.options).prevPrice | currency
                        }}</small
                      ></span
                    >
                    <h1
                      v-if="!requestPrice"
                      class="font-weight-medium text-no-wrap"
                    >
                      {{ data.price | currency }}
                      <small v-if="data.business === 1" class="title">
                        {{ labelPerMonth }}
                        <small v-if=" getOptions(data.options).asteristic" style="position: relative; top: -7px">*</small>
                        {{
                          getOptions(data.options).iptu &&
                          getOptions(data.options).iptu.included
                            ? labelPerMonthIncludeIPTU
                            : ""
                        }}
                      </small>
                    </h1>
                    <h2 v-else class="font-weight-medium text-wrap">
                      R$... valor sob consulta
                    </h2>
                    <span v-if="data.condominium"
                    ><small>Condomínio: </small></span
                    >
                    <span v-if="data.condominium"
                    ><small class="subtitle-1 font-weight-medium">{{
                      data.condominium | currency
                    }}</small></span
                    >
                    <span v-if="data.iptu === 0"
                    ><small>IPTU: </small
                      ><small class="subtitle-1 font-weight-medium"
                      >Livre</small
                      ></span
                      >
                      <span v-if="data.iptu > 0"
                        ><small>IPTU: </small
                          ><small class="subtitle-1 font-weight-medium"
                          >{{ getIptuPerMonth ? getIptuPerMonth : getIptu }}
                          <span>{{
                            getOptions(data.options).iptu
                            ? getOptions(data.options).iptu.label
                            : ""
                          }}</span></small
                      ></span
                      >
                      <v-btn
                      v-if="requestPrice"
                      class="mt-6"
                      color="primary"
                      x-large
                      :to="{
                        name: getContactFormName(data.business),
                        params: {
                          type: getBusiness(data.business),
                          business: getBusiness(data.business),
                          property: {
                            ...data,
                            link: $router.currentRoute.path,
                          },
                        },
                      }"
                      >{{ labelBtnKnowMore }}</v-btn
                      >
                      <div v-else class="d-flex flex-column">
                        <v-btn
                        class="mt-6"
                        color="primary"
                        x-large
                        :to="{
                          name: getContactFormName(data.business),
                          params: {
                            type: getBusiness(data.business),
                            business: getBusiness(data.business),
                            property: {
                              ...data,
                              link: $router.currentRoute.path,
                            },
                          },
                        }"
                        >{{ labelBtnWish }}
                        {{
                          getters["properties/getBusiness"](data.business)
                          | translateBusinessToAct
                        }}</v-btn
                      >
                      <div class="white d-flex flex-fill mt-5">
                        <v-btn
                        outlined
                        color="primary"
                        class="flex-fill"
                        large
                        :to="{
                          name: getContactFormName(data.business),
                          params: {
                            type: 'visit',
                            business: getBusiness(data.business),
                            property: {
                              ...data,
                              link: $router.currentRoute.path,
                            },
                          },
                        }"
                          >{{ labelBtnVisit }}</v-btn
                          >
                        </div>
                      </div>
                      <p style="font-size: 11px" class="pt-4 mb-0 pb-0" v-if="getOptions(data.options).asteristic">* {{ getOptions(data.options).asteristic }}</p>
                      <p style="font-size: 11px" class="pt-4 mb-0 pb-0">
                        * Os valores, condições e disponibilidade dos imóveis
                        podem sofrer alterações sem aviso prévio!
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import HouseSpecification from "@/components/contents/HouseSpecification";
import HeaderPanel from "@/components/headers/HeaderPanel";
import BackButton from "@/components/buttons/BackButton";
export default {
  name: "HouseDetails",
  components: { BackButton, HeaderPanel, HouseSpecification },
  props: ["data", "store", "headerPanel"],
  data: () => ({
    labelBtnBack: "voltar",
    labelCod: "cod",
    labelPerMonth: "/mês",
    labelPerMonthIncludeIPTU: " + IPTU",
    labelBtnWish: "quero",
    labelBtnKnowMore: "quero saber mais",
    labelBtnVisit: "quero visitar",
    labelHightLights: "destaque",
    showBtnAll: false,
  }),
  methods: {
    toLinkImageSlide(slide) {
      return {
        name:
          this.data?.business === 1
            ? "ForRentDetailsImageSlide"
            : "ForSaleDetailsImageSlide",
        params: { slide },
      };
    },
    getBusiness(value) {
      return value === 1 ? "rent" : value === 2 ? "sale" : "";
    },
    getContactFormName(value) {
      return value === 1
        ? "ContactFormRent"
        : value === 2
        ? "ContactFormSale"
        : "";
    },
    onMouseOverImages() {
      this.showBtnAll = true;
    },
    onMouseLeaveImages() {
      this.showBtnAll = false;
    },
    getOptions(opt) {
      return opt ? JSON.parse(opt) : {};
    },
  },
  computed: {
    getters() {
      return this.$store.getters;
    },
    requestPrice() {
      return (
        (this.data.business === 1 || this.data.business === 2) &&
        this.data.price === -1
      );
    },
    getIptu() {
      return this.$options.filters.currency(Number(this.data.iptu))
    },
    getIptuPerMonth() {
      return this.getOptions(this.data.options).iptu_months ? this.$options.filters.currency(Number(this.data.iptu) / this.getOptions(this.data.options).iptu_months) + " por mês" : null
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.store.status === 0 && !this.data)
        this.$router.push({
          name: "NotFound",
          params: { url: location.origin + this.$route.fullPath },
        });
    }, 2000);
  },
};
</script>

